import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Loading from "../Loading";
import DarkNavigationBar from "../DarkNavigation/DarkNavigationBar";
import Action from "../Action";
import Footer from "../Footer";
import PartnerLogo from "../PartnerLogo";

import { Link } from "react-router-dom";
import { FaHandPointer, FaCheckCircle } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import "./ThankYouPage.css";

function ThankYouPage() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  });
  return (
    <>
      {isLoading === true ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>Thank You - Novela Films</title>
            <link rel="canonical" href="https://novelafilms.com/thankyou/" />
            {/* Meta tags */}
            <meta
              name="description"
              content="Join us to explore limitless possibilities in visual storytelling. Let's create together and leave a mark on the world."
            />
            <meta name="keywords" content="Contact Us, Novela Films" />
            <meta property="og:title" content="Contact Us - Novela Films" />
            <meta
              property="og:description"
              content="Join us to explore limitless possibilities in visual storytelling. Let's create together and leave a mark on the world."
            />
            <meta
              property="og:image"
              content="https://novelafilms.com/novela-films.png"
            />
          </Helmet>
          <DarkNavigationBar />
          <Container
            fluid
            className="thank-you-container"
          >
            <div >
              <FaCheckCircle className="checkmark"/>
            </div>
            <h1 className="thank-you-heading">Thank You!</h1>
            <p className="thank-you-message">
              Thank you very much for filling out our form. We'll get back to
              you soon!
            </p>

            <Link to="/process" className="button-color-bg">
              <span className="button-color-bg-text">
                <FaHandPointer /> Know More
              </span>
              <span className="button-color-bg-fill"></span>
            </Link>
          </Container>
          <PartnerLogo />
          <br />
          <Action />
          <Footer />
        </>
      )}
    </>
  );
}

export default ThankYouPage;
