import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/* Main Pages Import */
import ServicesParentPage from "./Pages/OurServices/ServicesParentPage";
import PortfolioPage from "./Pages/portfoliopage";
import ProcessPage from "./Pages/processpage";
import ClientsPage from "./Pages/clientspage";
import ResoursesPage from "./Pages/resoursespage";
import ContactPage from "./Pages/contactpage";
import GalleryPage from "./Pages/gallerypage";
import DownloadEbookPage from "./Pages/DownloadEbookPage";

/* Sub Pages Import*/
import ServicesChildPage from "./Pages/OurServices/ServicesChildPage";
import BlogPage from "./Pages/BlogPage";
import ErrorPage from "./components/ErrorPage";
import ThankYouPage from './components/ThankYou/ThankYouPage';
// import FetchData from './components/FetchData';
import Loading from './components/Loading';
// import TestPage from "./components/Test";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { HelmetProvider } from 'react-helmet-async';
//import Scrollbar from 'smooth-scrollbar';

const root = ReactDOM.createRoot(document.getElementById('root'));

// var options = {
//      'damping': 0.02
//    }
//    Scrollbar.init(document.querySelector('#scrollbar'), options);

root.render(
  <Router>
  <HelmetProvider>
    <Routes>
      <Route
        exact
        path="/"
        element={
          <React.Suspense fallback={<Loading />}>
          <App />      
          </React.Suspense>
        }
      />
      <Route
        path="/services"
        element={
          <React.Suspense fallback={<Loading />}>
          <ServicesParentPage />         
          </React.Suspense>
        }
      />
      <Route
        path="/portfolio"
        element={
          <React.Suspense fallback={<Loading />}>
          <PortfolioPage />
          </React.Suspense>
        }
      />
      <Route
        path="/process"
        element={
          <React.Suspense fallback={<Loading />}>
          <ProcessPage />
          </React.Suspense>
        }
      />
      <Route
        path="/clients"
        element={
          <React.Suspense fallback={<Loading />}>
          <ClientsPage />
          </React.Suspense>
        }
      />
      <Route
        path="/resources"
        element={
          <React.Suspense fallback={<Loading />}>
          <ResoursesPage />
          </React.Suspense>
        }
      />
      <Route
        path="/contact"
        element={
          <React.Suspense fallback={<Loading />}>
            <ContactPage />
          </React.Suspense>
        }
      />
      <Route
        path="/gallery"
        element={
          <React.Suspense fallback={<Loading />}>
            <GalleryPage />
          </React.Suspense>
        }
      />

      
      <Route
        path="/services/:serviceName"
        element={
          <React.Suspense fallback={<Loading />}>
          <ServicesChildPage />
          </React.Suspense>
        }
      />
      <Route
        path="/resources/ebook-download/:ebookName"
        element={
          <React.Suspense fallback={<Loading />}>
            <DownloadEbookPage />
          </React.Suspense>
        }
      />
      <Route
        path="/Blogs/:blogName"
        element={
          <React.Suspense fallback={<Loading />}>
            <BlogPage />
          </React.Suspense>
        }
      />
      <Route
        path="*"
        element={
          <React.Suspense fallback={<Loading />}>
            <ErrorPage />
          </React.Suspense>
        }
      />
      <Route
        path="/thankyou"
        element={
          <React.Suspense fallback={<Loading />}>
            <ThankYouPage />
          </React.Suspense>
        }
      />
      {/* <Route
        path="/admin"
        element={
          <React.Suspense fallback={<Loading />}>
            <FetchData />
          </React.Suspense>
        }
      /> */}
      {/* <Route
        path="/test"
        element={
          <React.Suspense fallback={<Loading />}>
            <TestPage />
          </React.Suspense>
        }
      /> */}
      {/* <Route
        path="/loading"
        element={
          <React.Suspense fallback={<Loading />}>
            <Loading />
          </React.Suspense>
        }
      /> */}
    </Routes>
   </HelmetProvider>
  </Router>
);

// app to work offline and load faster, change unregister to register
//serviceWorkerRegistration.unregister();
