const serviceslist = [
    {
        key: 1,
        id: 1,
        //SERVICE AND HELMET INFO
        serviceNAME: "animation explainer videos",
        serviceDESC: "Animated explainer videos are short videos that come in various types, including character animation, whiteboard animation for educational content, motion graphics for complex concepts, 2D animation for storytelling and branding, and 3D animation for added depth and realism.",
        serviceIMG: require("../data/images/services/parent/2d-animation-explainer.webp"),
        serviceIMGALT: "2d animation explainer videos novela films private limited",
        serviceLink: "https://novelafilms.com/services/animation-explainer-videos",
        serviceKeywords:["animation explainer videos"],
        // SERVICE PAGE HERO SECTION
        heroIMG: require("../data/images/services/child/2d-animation-bg-default.webp"),
        heroIMG_SM: require("../data/images/services/child/2d-animation-bg-sm.webp"),
        heroIMG_MD: require("../data/images/services/child/2d-animation-bg-md.webp"),
        heroIMG_LG: require("../data/images/services/child/2d-animation-bg-lg.webp"),
        heroIMGDETAIL: "animation explainer",
        heroVIDEOURL: "https://www.youtube.com/watch?v=q-4U5lC4tTU",
        heroTITLE1: "Animated",
        heroTITLE2: "Explainer Video",
        heroTITLE3: "Production",
        // SERVICE DESCRIPTION
        descTEXT1: "Transform your brand's message into a visual masterpiece with our animated explainer videos that captivate and engage your audience.",
        descTEXT2: "",
        descTEXT3: "What is an Animated\nExplainer Video?",
        descTEXT4: "An explainer video is a concise and engaging audio-visual tool that simplifies complex ideas, products, or services in a visually appealing manner. Typically lasting 60 to 120 seconds, these videos use visuals, motion graphics, voiceovers, and sound effects to convey information effectively. Videos, such as Saas explainer videos and product explainer videos, have shown their remarkable power in captivating audiences and leaving a lasting impact on viewers.",
        descTEXT5: "The power of explainer videos lies in their ability to:",
        descTEXT6: [
            {
                "title": "Simplify Your Message"
            },
            {
                "title": "Boost Conversions"
            },
            {
                "title": "Generate Leads"
            },
            {
                "title": "Reduce Bounce Rates"
            },
            {
                "title": "Foster Brand Awareness"
            },
            {
                "title": "Communicate Concisely"
            }
        ],
        descIMG: require("../data/images/services/child/animated-explainer-video-default.webp"),
        descIMG_SM: require("../data/images/services/child/animated-explainer-video-sm.webp"),
        descIMG_MD: require("../data/images/services/child/animated-explainer-video-md.webp"),
        descIMGALT: "Animated Explainer Video",
        descTEXT7: "Investing in an animated explainer video can yield impressive returns for your business.\n",
        descTEXT9: "It's a win-win situation, wouldn't you agree?",
        // WHY CHOOSE NOVELA
        wcnEXP: "With 15+ years of deep expertise in the animation explainer video services space, we have honed our skills and\n gained invaluable insights. Our wealth of knowledge\n allows us to tackle any challenge with confidence and\n precision. Trust in our expertise to deliver exceptional\n results and meet your unique requirements.",
        wcnFD: "At Novela Films, we thrive on the challenge of working with fixed deadlines. With our dedicated team and streamlined processes, we excel at delivering high quality results within given timeframes. Our commiment to meeting fixed deadlines ensures that your projects are completed on time, without compromising on quality or creativity.",
        wcnQC: "We prioritize quality control to ensure exceptional results. Our dedicated team works diligently to implement rigorous quality checks throughout every stage of the process. Our commitment to quality guarantees client satisfaction and sets us apart from the competition.",
        wcnSO: "Our animated explainer video production process is meticulously structured, ensuring clarity and high level of organization. It encompasses well-defined steps, multiple layers of quality control, and fixed turnaround times, providing our clients with a seamless journey from initiation to completion.",
        wcnTOE: "Novela Films boasts a skilled and passionate team of experts, including designers, producers, directors, animators, and writers. Through their collaboration, we ensure meticulous attention to detail and deliver exceptional animated explainer videos that captivate and engage your audience. Trust us to handle your project with professionalism and creativity.",
        wcnTP: "During our initial call, we have a thorough discussion about your requirements for an animated explainer video. Based on this, we create a detailed proposal that outlines how we will bring your ideas to life. You can trust us to be transparent and there will be no hidden fees or unexpected costs.",
        // FLAG 1
        flagText: "Create a Explainer Video!",
        flag2Text: "Work on Your Next Video!",
        // OUR WORK
        owTEXT: "Unleash your imagination in a world of animation and storytelling.\n\n Our portfolio showcases boundless creativity and innovation in the animation space.\n\n Explore diverse narratives, feel the magic, and get inspired! Discover the art of visual storytelling",
        videoList: [1, 2, 11, 8, 32, 14],
        // OUR PROCESS STEP 1
        opS1Title: "Video Brief",
        opS1Text: "A clear and concise explainer video brief aligns stakeholders, ensures clarity in objectives, content, style, and messaging. It saves time, promotes efficiency, minimizes revisions, and acts as a roadmap to keep production focused on goals and target audience. It facilitates evaluation, feedback, and productive discussions for adjustments.",
        opS1Img: require("../data/images/process/video-brief.webp"),
        opS1ImgAlt: "animation explainer video brief novela films private limited",
        // OUR PROCESS STEP 2
        opS2Title: "The Kick-Off Call",
        opS2Text: "During the kick-off call, team members introduce themselves and discuss project scope, roles and responsibilities, and the communication plans. The project timeline is outlined, challenges are identified, and next steps are determined. This call establishes expectations and promotes successful collaboration, communication, and project management for the duration of the project.",
        opS2Img: require("../data/images/process/kick-off-call.webp"),
        opS2ImgAlt: "animation explainer kick off call novela films private limited",
        // OUR PROCESS STEP 3
        opS3Title: "Script",
        opS3Text: "The script ensures the message is delivered in a concise and engaging manner, utilizing storytelling techniques to captivate the audience. The script also fosters collaboration, maintains control over timing and information flow, and effectively communicates the video's intended message and goals.",
        opS3Img: require("../data/images/process/script.webp"),
        opS3ImgAlt: "animation explainer script novela films private limited",
        // OUR PROCESS STEP 4
        opS4Title: "Voiceover",
        opS4Text: "The voiceover is the captivating thread that weaves the story together. It plays a vital role in conveying the information, guiding the audience, and enhancing the overall impact of the explainer video. A well-executed voiceover in a explainer video should be clear, engaging, and aligned with the intended tone and messaging.",
        opS4Img: require("../data/images/process/voiceover.webp"),
        opS4ImgAlt: "animation explainer voiceover novela films private limited",
        // OUR PROCESS STEP 5
        opS5Title: "Storyboarding",
        opS5Text: "A storyboard visually represents the actions and flow of a video, similar to a comic book. The storyboard artist sketches out a series of frames that depict the flow of the video, including the scenes, actions, and characters involved. It serves as a communication tool between creators and clients, facilitating feedback and revisions before production starts.",
        opS5Img: require("../data/images/process/storyboarding.webp"),
        opS5ImgAlt: "animation explainer storyboarding novela films private limited",
        // OUR PROCESS STEP 6
        opS6Title: "Animation",
        opS6Text: "The animation part of an explainer video plays a crucial role in bringing the visual elements to life, enhancing engagement, and effectively communicating the message to the audience. It requires technical skill, creativity, and attention to detail to create animations that are visually appealing, engaging, and forms an emotional connection with the audience.",
        opS6Img: require("../data/images/process/2d-animation.webp"),
        opS6ImgAlt: "animation explainer animation novela films private limited",
        // OUR PROCESS STEP 7
        opS7Title: "Draft Video",
        opS7Text: "Once the production of the explainer video is completed, a draft video will be shared for approval and any suggested changes will be incorporated at this stage.",
        opS7Img: require("../data/images/process/draft-video-1.webp"),
        opS7ImgAlt: "animation explainer draft video novela films private limited",
        // OUR PROCESS STEP 8
        opS8Title: "Iterations",
        opS8Text: "At Novela Films, we include two iterations at each step of the process with a capping of six iterations overall, and we will only proceed once the previous step is approved.",
        opS8Img: require("../data/images/process/iterations.webp"),
        opS8ImgAlt: "animation explainer iterations novela films private limited",
        // OUR VIDEOS SLIDER
        sliderVL: [1, 2, 11, 8, 32, 14, 101, 102, 103],
    },
    {
        key: 2,
        id: 2,
        //SERVICE AND HELMET INFO
        serviceNAME: "motion graphics",
        serviceDESC: "Motion graphics usually includes typography, infographics, transitions, illustrations, icons and CTA's. Motion graphicscommonly used in explainer videos, promotional videos, education content, advertisements, and many more.",
        serviceIMG: require("../data/images/services/parent/motion-graphics.webp"),
        serviceIMGALT: "motion graphics videos novela films private limited",
        serviceLink: "https://novelafilms.com/services/motion-graphics",
        serviceKeywords:["motion graphics"],
        // SERVICE PAGE HERO SECTION
        heroIMG: require("../data/images/services/child/motion-graphics-bg-default.webp"),
        heroIMG_SM: require("../data/images/services/child/motion-graphics-bg-sm.webp"),
        heroIMG_MD: require("../data/images/services/child/motion-graphics-bg-md.webp"),
        heroIMG_LG: require("../data/images/services/child/motion-graphics-bg-lg.webp"),
        heroIMGDETAIL: "motion graphics",
        heroVIDEOURL: "https://www.youtube.com/watch?v=q-4U5lC4tTU",
        heroTITLE2: "Motion\nGraphics",
        heroTITLE3: "Animation",
        // SERVICE DESCRIPTION
        descTEXT1: "Motion Graphics: Transforming information into emotion",
        descTEXT2: "",
        descTEXT3: "What is a Motion Graphic Video?",
        descTEXT4: "A motion graphic video is a captivating and visually engaging form of animation that combines moving images, text, and graphic elements to convey a message or tell a story. Unlike traditional videos, motion graphic videos rely on graphic design and animation techniques rather than live-action footage.",
        descTEXT5: "Motion graphic videos simplify complex ideas, present data visually, and grab the viewer's attention by incorporating dynamic transitions, kinetic typography, illustrations, and icons. Motion graphics are commonly used in explainer videos, promotional videos, educational content, advertisements, and many more. The power of Motion graphic videos lies in their ability to:",
        descTEXT6: [
            {
                "title": "Improve Engagement"
            },
            {
                "title": "Increase Conversion Rates"
            },
            {
                "title": "Improve Message Retention"
            },
            {
                "title": "Enhance Brand Perception"
            },
            {
                "title": "Drive Social Media Sharing"
            },
            {
                "title": "Enhanced Storytelling"
            }
        ],
        descIMG: require("../data/images/services/child/motion-graphics-default.webp"),
        descIMG_SM: require("../data/images/services/child/motion-graphics-sm.webp"),
        descIMG_MD: require("../data/images/services/child/motion-graphics-md.webp"),
        descIMGALT: "Motion Graphic Video",
        descTEXT7: "Investing in motion graphic videos, also known as animated graphics or motion design,\n has the potential to yield exceptional business returns,\n",
        descTEXT9: "making it a win-win proposition.",
        // WHY CHOOSE NOVELA
        wcnEXP: "With 15+ years of deep expertise in the motion graphic animation, we have honed our skills and gained invaluable insights. Our wealth of knowledge allows us to tackle any challenge with confidence and precision. Trust in our expertise to deliver exceptional results and meet your unique requirements.",
        wcnFD: "At Novela Films, we thrive on the challenge of working with fixed deadlines. With our dedicated team and streamlined processes, we excel at delivering high quality results within given timeframes. Our commiment to meeting fixed deadlines ensures that your projects are completed on time, without compromising on quality or creativity.",
        wcnQC: "We prioritize quality control to ensure exceptional results. Our dedicated team works diligently to implement rigorous quality checks throughout every stage of the process. Our commitment to quality guarantees client satisfaction and sets us apart from the competition.",
        wcnSO: "Our motion graphics animation process is meticulously structured to provide clarity and maintain a superior level of organization. It encompasses well-defined steps, multiple layers of quality control, and fixed turnaround times, providing our clients with a seamless journey from initiation to completion.",
        wcnTOE: "At Novela Films, our team is a powerhouse of creativity and expertise, bringing together motion graphic designers, producers, directors, animators, and writers to craft outstanding visual experiences. Through their collaboration, we ensure meticulous attention to detail and deliver exceptional animated graphics that captivate and engage your audience. ",
        wcnTP: "During the kick-off call, we will carefully analyze your motion graphics video requirements and create a comprehensive proposal that outlines our approach to help bring your ideas to life. Rest assured, we prioritize transparency, ensuring there are no hidden fees or unforeseen costs along the way.",
        // FLAG 1
        flagText: "Create a Motion Graphic Video!",
        flag2Text: "Work on Your Next Video!",
        // OUR WORK
        owTEXT: "Witness a delightful fusion of creativity and innovation.\n\n We invite you to explore a diverse collection that showcases our unique approach in motion graphic space.\n\n Take a moment to discover the magic within our portfolio.",
        videoList: [8, 1, 11, 27, 39, 38],
        // OUR PROCESS STEP 1
        opS1Title: "Video Brief",
        opS1Text: "A clear and concise motion graphic video brief aligns stakeholders, ensures clarity in objectives, content, style, and messaging. It saves time, promotes efficiency, minimizes revisions, and acts as a roadmap to keep production focused on goals and target audience. It facilitates evaluation, feedback, and productive discussions for adjustments.",
        opS1Img: require("../data/images/process/video-brief.webp"),
        opS1ImgAlt: "motion graphics video brief novela films private limited",
        // OUR PROCESS STEP 2
        opS2Title: "The Kick-Off Call",
        opS2Text: "During the kick-off call, team members introduce themselves and discuss project scope, roles and responsibilities, and the communication plans. The project timeline is outlined, challenges are identified, and next steps are determined. This call establishes expectations and promotes successful collaboration, communication, and project management for the duration of the project.",
        opS2Img: require("../data/images/process/kick-off-call.webp"),
        opS2ImgAlt: "motion graphics kick off call novela films private limited",
        // OUR PROCESS STEP 3
        opS3Title: "Script",
        opS3Text: "The script ensures the message is delivered in a concise and engaging manner, utilizing storytelling techniques to captivate the audience. The script also fosters collaboration, maintains control over timing and information flow, and effectively communicates the video's intended message and goals.",
        opS3Img: require("../data/images/process/script.webp"),
        opS3ImgAlt: "motion graphics script novela films private limited",
        // OUR PROCESS STEP 4
        opS4Title: "Voiceover",
        opS4Text: "The voiceover is the captivating thread that weaves the story together. It plays a vital role in conveying the information, guiding the audience, and enhancing the overall impact of the motion graphic video. A well-executed voiceover in a motion graphic video should be clear, engaging, and aligned with the intended tone and messaging.",
        opS4Img: require("../data/images/process/voiceover.webp"),
        opS4ImgAlt: "motion graphics voiceover novela films private limited",
        // OUR PROCESS STEP 5
        opS5Title: "Storyboarding",
        opS5Text: "A storyboard visually represents the actions and flow of a video, similar to a comic book. The storyboard artist sketches out a series of frames that depict the flow of the video, including the scenes, actions, and characters involved. It serves as a communication tool between creators and clients, facilitating feedback and revisions before production starts.",
        opS5Img: require("../data/images/process/storyboarding.webp"),
        opS5ImgAlt: "motion graphics storyboarding novela films private limited",
        // OUR PROCESS STEP 6
        opS6Title: "Animation",
        opS6Text: "The animation part of an motion graphic video plays a crucial role in bringing the visual elements to life, enhancing engagement, and effectively communicating the message to the audience. It requires technical skill, creativity, and attention to detail to create animations that are visually appealing, engaging, and forms an emotional connection with the audience.",
        opS6Img: require("../data/images/process/motion-graphic-animation.webp"),
        opS6ImgAlt: "motion graphics animation novela films private limited",
        // OUR PROCESS STEP 7
        opS7Title: "Draft Video",
        opS7Text: "Once the production of the motion graphic video is completed, a draft video will be shared for approval and any suggested changes will be incorporated at this stage.",
        opS7Img: require("../data/images/process/draft-video-1.webp"),
        opS7ImgAlt: "motion graphics draft video novela films private limited",
        // OUR PROCESS STEP 8
        opS8Title: "Iterations",
        opS8Text: "At Novela Films, we include two iterations at each step of the process with a capping of six iterations overall, and we will only proceed once the previous step is approved.",
        opS8Img: require("../data/images/process/iterations.webp"),
        opS8ImgAlt: "motion graphics iterations novela films private limited",
        // OUR VIDEOS SLIDER        
        sliderVL: [8, 1, 11, 27, 39, 38, 101, 102, 103]
    },
    {
        key: 3,
        id: 3,
        //SERVICE AND HELMET INFO
        serviceNAME: "corporate videos",
        serviceDESC: "Company overviews, customer success stories, testimonials, promotional videos, corporate events coverage, and animated explainer videos are some of the diverse types of corporate videos available.",
        serviceIMG: require("../data/images/services/parent/corporate-videos.webp"),
        serviceIMGALT: "corporate videos novela films private limited",
        serviceLink: "https://novelafilms.com/services/corporate-videos",
        serviceKeywords:["corporate videos"],
        // SERVICE PAGE HERO SECTION
        heroIMG: require("../data/images/services/child/corporate-video-bg-default.webp"),
        heroIMG_SM: require("../data/images/services/child/corporate-video-bg-sm.webp"),
        heroIMG_MD: require("../data/images/services/child/corporate-video-bg-md.webp"),
        heroIMG_LG: require("../data/images/services/child/corporate-video-bg-lg.webp"),
        heroIMGDETAIL: "corporate videos",
        heroVIDEOURL: "https://www.youtube.com/watch?v=q-4U5lC4tTU",
        heroTITLE2: "Corporate\nVideo",
        heroTITLE3: "Production",
        // SERVICE DESCRIPTION
        descTEXT1: "Corporate Videos showcase your company's expertise, achievements, and capabilities, positioning you as a leader in your industry.\n",
        descTEXT2: "",
        descTEXT3: "What is a Corporate Video?",
        descTEXT4: "Corporate videos are created by businesses for promotional, educational, or internal communication purposes. It serves as a valuable tool for conveying a company's brand message, showcasing products or services, and engaging stakeholders.",
        descTEXT5: "Company overviews, customer success stories, testimonials, promotional videos, corporate events coverage, and animated explainer videos are some of the diverse types of corporate videos available. The power of corporate videos lies in their ability to:",
        descTEXT6: [
            {
                "title": "Simplify Your Message"
            },
            {
                "title": "Enhance brand identity"
            },
            {
                "title": "Easy employee communication"
            },
            {
                "title": "Support investor relations"
            },
            {
                "title": "Demonstrate expertise"
            },
            {
                "title": "Advertise business"
            }
        ],
        descIMG: require("../data/images/services/child/corporate-video-default.webp"),
        descIMG_SM: require("../data/images/services/child/corporate-video-sm.webp"),
        descIMG_MD: require("../data/images/services/child/corporate-video-md.webp"),
        descIMGALT: "Corporate Video",
        descTEXT7: "Empower your business with captivating corporate videos that deliver remarkable returns.",
        descTEXT9: "Isn't this a win-win opportunity to unlock success?",
        // WHY CHOOSE NOVELA
        wcnEXP: "With 15+ years of deep expertise in the Corporate video production space, we have honed our skills and gained invaluable insights. Our wealth of knowledge allows us to tackle any challenge with confidence and precision. Trust in our expertise to deliver exceptional results and meet your unique requirements.",
        wcnFD: "At Novela Films, we thrive on the challenge of working with fixed deadlines. With our dedicated team and streamlined processes, we excel at delivering high quality results within given timeframes. Our commiment to meeting fixed deadlines ensures that your projects are completed on time, without compromising on quality or creativity.",
        wcnQC: "We prioritize quality control to ensure exceptional results. Our dedicated team works diligently to implement rigorous quality checks throughout every stage of the process. Our commitment to quality guarantees client satisfaction and sets us apart from the competition.",
        wcnSO: "Our corporate video production process is meticulously structured, ensuring clarity and high level of organization. It encompasses well-defined steps, multiple layers of quality control, and fixed turnaround times, providing our clients with a seamless journey from initiation to completion.",
        wcnTOE: "Novela Films boasts a skilled and passionate team of experts, including designers, editors, directors, animators, and writers. Through their collaboration, we ensure meticulous attention to detail and deliver exceptional animated explainer videos that captivate and engage your audience. Trust us to handle your project with professionalism and creativity.",
        wcnTP: "During our initial call, we have a thorough discussion about your requirements for the corporate video. Based on this, we create a detailed proposal that outlines how we will bring your ideas to life. You can trust us to be transparent and there will be no hidden fees or unexpected costs.",
        // FLAG 1
        flagText: "Create a Corporate Video!",
        flag2Text: "Work on Your Next Video!",
        // OUR WORK
        // owTEXT: "Take a tour of our Corporate portfolio and you’ll see a perfect blend of creativity and innovation.",
        owTEXT: "Witness a delightful fusion of creativity and innovation.\n\n We invite you to explore a diverse collection that showcases our unique approach in corporate video space.\n\n Take a moment to discover the magic within our portfolio.",
        videoList: [5, 12, 24, 22, 18, 28],
        // OUR PROCESS STEP 1
        opS1Title: "Video Brief",
        opS1Text: "A concise product video brief aligns stakeholders, ensures clarity in objectives, content, style, and messaging. It saves time, promotes efficiency, minimizes revisions, and acts as a roadmap to keep production focused on goals and target audience. It facilitates evaluation, feedback, and productive discussions for adjustments.",
        opS1Img: require("../data/images/process/video-brief.webp"),
        opS1ImgAlt: "corporate video brief novela films private limited",
        // OUR PROCESS STEP 2
        opS2Title: "The Kick-Off Call",
        opS2Text: "During the kick-off call, team members introduce themselves and discuss project scope, roles and responsibilities, and the communication plans. The project timeline is outlined, challenges are identified, and next steps are determined. This call establishes expectations and promotes successful collaboration, communication, and project management for the duration of the project.",
        opS2Img: require("../data/images/process/kick-off-call.webp"),
        opS2ImgAlt: "corporate video kick off call novela films private limited",
        // OUR PROCESS STEP 3
        opS3Title: "Script",
        opS3Text: "The script ensures the message is delivered in a concise and engaging manner, utilizing storytelling techniques to captivate the audience. The script also fosters collaboration, maintains control over timing and information flow, and effectively communicates the video's intended message and goals.",
        opS3Img: require("../data/images/process/script.webp"),
        opS3ImgAlt: "corporate video script novela films private limited",
        // OUR PROCESS STEP 4
        opS4Title: "Pre-Production",
        opS4Text: "Pre-production is the initial phase of filmmaking or video production. It involves planning, budgeting, storyboarding, casting, location scouting, and assembling the production team. This stage sets the foundation for a successful production, organizing creative aspects, schedules, and key decisions before filming.",
        opS4Img: require("../data/images/process/pre-production.webp"),
        opS4ImgAlt: "corporate video pre-production novela films private limited",
        // OUR PROCESS STEP 5
        opS5Title: "Production / On-set Filming",
        opS5Text: "Production encompasses the act of capturing footage either on location or in a controlled studio enviroment. A meticulously executed production or on-set filming significantly enhances the overall impact of a product video by crafting visually captivating and compelling content that truly engages the target audience.",
        opS5Img: require("../data/images/process/production.webp"),
        opS5ImgAlt: "corporate video production novela films private limited",
        // OUR PROCESS STEP 6
        opS6Title: "Post Production",
        opS6Text: "Post-production is the final step in video creation. During this stage, the footage is edited, and elements like visual effects and sound design are included to enhance the viewer's experience. It's an important process that fine-tunes and enhances the video, resulting in a captivating and impactful final product that resonates with the audience.",
        opS6Img: require("../data/images/services/child/animated-explainer-video-default.webp"),
        opS6ImgAlt: "corporate video post production novela films private limited",
        // OUR PROCESS STEP 7
        opS7Title: "Draft Video",
        opS7Text: "Once the production of the video is completed, a draft video will be shared for approval and any suggested changes will be incorporated at this stage.",
        opS7Img: require("../data/images/process/draft-video-1.webp"),
        opS7ImgAlt: "corporate video draft video novela films private limited",
        // OUR PROCESS STEP 8
        opS8Title: "Finalization and Delivery",
        opS8Text: "Once the draft is approved and payments are made, we proceed to share the final video, fully polished and ready for distribution, ensuring client satisfaction and effective product showcasing.",
        opS8Img: require("../data/images/process/final-delivery.webp"),
        opS8ImgAlt: "corporate video finalization and delivery novela films private limited",
        // OUR VIDEOS SLIDER
        sliderVL: [5, 12, 24, 22, 18, 28, 101, 102, 103]
    },
    {
        key: 4,
        id: 4,
        //SERVICE AND HELMET INFO
        serviceNAME: "video editing",
        serviceDESC: "Video editing utilizes techniques like visual storytelling, audio editing, and photo editing to skillfully manipulate video footages, creating a captivating and seamless video product that engages your audience.",
        serviceIMG: require("../data/images/services/parent/video-editing.webp"),
        serviceIMGALT: "Video editing novela films private limited",
        serviceLink: "https://novelafilms.com/services/video-editing",
        serviceKeywords:["video editing"],
        // SERVICE PAGE HERO SECTION
        heroIMG: require("../data/images/services/child/video-editing-bg-default.webp"),
        heroIMG_SM: require("../data/images/services/child/video-editing-bg-sm.webp"),
        heroIMG_MD: require("../data/images/services/child/video-editing-bg-md.webp"),
        heroIMG_LG: require("../data/images/services/child/video-editing-bg-lg.webp"),
        heroIMGDETAIL: "Video editing",
        heroVIDEOURL: "https://www.youtube.com/watch?v=q-4U5lC4tTU",
        heroTITLE2: "Video",
        heroTITLE3: "Editing",
        // SERVICE DESCRIPTION
        descTEXT1: "Video editing is the magic that turns raw footage into a polished masterpiece.",
        descTEXT2: "",
        descTEXT3: "What is\n Video Editing?",
        descTEXT4: "Post production services such as Video editing is a multi-layered process that blends visual storytelling, audio editing, and photo editing. The outcome is a captivating and seamless video that engages the audience and effectively communicates the intended message or story.",
        descTEXT5: "Video editing is a critical component for all kind of videos including live action, motion graphics, and animation, allowing for the creation of compelling videos that leave a lasting impact on viewers. The power of video editing lies in their ability to:",
        descTEXT6: [
            {
                "title": "Simplify Your Message"
            },
            {
                "title": "Boost Conversions"
            },
            {
                "title": "Generate Leads"
            },
            {
                "title": "Reduce Bounce Rates"
            },
            {
                "title": "Foster Brand Awareness"
            },
            {
                "title": "Communicate Concisely"
            }
        ],
        descIMG: require("../data/images/services/child/animated-explainer-video-default.webp"),
        descIMG_SM: require("../data/images/services/child/animated-explainer-video-sm.webp"),
        descIMG_MD: require("../data/images/services/child/animated-explainer-video-md.webp"),
        descIMGALT: "Video Editing",
        descTEXT7: "Invest in the art of video editing,",
        descTEXT9: "and witness a win-win masterpiece unfold for your business.",
        // WHY CHOOSE NOVELA
        wcnEXP: "With 15+ years of deep expertise in the video editing and post production services space, we have honed our skills and gained invaluable insights. Our wealth of knowledge allows us to tackle any challenge with confidence and precision. Trust in our expertise to deliver exceptional results and meet your unique requirements.",
        wcnFD: "At Novela Films, we thrive on the challenge of working with fixed deadlines. With our dedicated team and streamlined processes, we excel at delivering high quality results within given timeframes. Our commiment to meeting fixed deadlines ensures that your projects are completed on time, without compromising on quality or creativity.",
        wcnQC: "We prioritize quality control to ensure exceptional results. Our dedicated team works diligently to implement rigorous quality checks throughout every stage of the process. Our commitment to quality guarantees client satisfaction and sets us apart from the competition.",
        wcnSO: "Our video editing process is meticulously structured, ensuring clarity and high level of organization. It encompasses well-defined steps, multiple layers of quality control, and fixed turnaround times, providing our clients with a seamless journey from initiation to completion.",
        wcnTOE: "Novela Films boasts a skilled and passionate team of experts, including video editors, producers, directors, animators, and writers. Through their collaboration, we ensure meticulous attention to detail and deliver exceptional edited videos that captivate and engage your audience. Trust us to handle your project with professionalism and creativity.",
        wcnTP: "During our initial call, we have a thorough discussion about your requirements for editing a video. Based on this, we create a detailed proposal that outlines how we will bring your ideas to life. You can trust us to be transparent and there will be no hidden fees or unexpected costs.",
         // FLAG 1
         flagText: "Create a Video!",
         flag2Text: "Work on Your Next Video!",
        // OUR WORK
        // owTEXT: "Take a tour of our Corporate portfolio and you’ll see a perfect blend of creativity and innovation.",
        owTEXT: "Witness a delightful fusion of creativity and innovation.\n\n We invite you to explore a diverse collection that showcases our unique approach in video editing space.\n\n Take a moment to discover the magic within our portfolio.",
        videoList: [24, 20, 4, 54, 55, 56],
        // OUR PROCESS STEP 1
        opS1Title: "Video Brief",
        opS1Text: "A concise video editing brief aligns stakeholders, ensures clarity in objectives, content, style, and messaging. It saves time, promotes efficiency, minimizes revisions, and acts as a roadmap to keep production focused on goals and target audience. It facilitates evaluation, feedback, and productive discussions for adjustments.",
        opS1Img: require("../data/images/process/video-brief.webp"),
        opS1ImgAlt: "video editing brief novela films private limited",
        // OUR PROCESS STEP 2
        opS2Title: "The Kick-Off Call",
        opS2Text: "During the kick-off call, team members introduce themselves and discuss project scope, roles and responsibilities, and the communication plans. The project timeline is outlined, challenges are identified, and next steps are determined. This call establishes expectations and promotes successful collaboration, communication, and project management for the duration of the project.",
        opS2Img: require("../data/images/process/kick-off-call.webp"),
        opS2ImgAlt: "video editing kick off call novela films private limited",
        // OUR PROCESS STEP 3
        opS3Title: "Script",
        opS3Text: "The script ensures the message is delivered in a concise and engaging manner, utilizing storytelling techniques to captivate the audience. We typically handle script creation, but we value and welcome client input, especially for any special requirements they may have.",
        opS3Img: require("../data/images/process/script.webp"),
        opS3ImgAlt: "video editing script novela films private limited",
        // OUR PROCESS STEP 4
        opS4Title: "Pre-Production",
        opS4Text: "Pre-production is the initial phase of filmmaking or video production. It's when storyboards are created to visualize the scenes, and actors are chosen after auditions to fit the roles. This stage sets the foundation for a successful production, organizing creative aspects, schedules, and key decisions before editing.",
        opS4Img: require("../data/images/process/pre-production-ve.webp"),
        opS4ImgAlt: "video editing pre-production novela films private limited",
        // OUR PROCESS STEP 5
        opS5Title: "Importing Media",
        opS5Text: "Importing media in video editing is the key to unleashing a world of creative possibilities. It empowers editors with a diverse range of audio, video, and other resources, laying the foundation for crafting captivating stories with seamless visuals and compelling narratives.",
        opS5Img: require("../data/images/process/importing-media.webp"),
        opS5ImgAlt: "video editing importing media novela films private limited",
        // OUR PROCESS STEP 6
        opS6Title: "Editing",
        opS6Text: "Editing is the final step in video creation process . During this stage, the footage is edited, and elements like visual effects and sound design are included to enhance the viewer's experience. It's an important process that fine-tunes and enhances the video, resulting in a captivating and impactful final product that resonates with the audience.",
        opS6Img: require("../data/images/services/child/animated-explainer-video-default.webp"),
        opS6ImgAlt: "video editing novela films private limited",
        // OUR PROCESS STEP 7
        opS7Title: "Draft Video",
        opS7Text: "Once the production of the video is completed, a draft video will be shared for approval and any suggested changes will be incorporated at this stage.",
        opS7Img: require("../data/images/process/draft-video-1.webp"),
        opS7ImgAlt: "video editing draft video novela films private limited",
        // OUR PROCESS STEP 8
        opS8Title: "Iterations",
        opS8Text: "At Novela Films, we include two iterations at each step of the process with a capping of six iterations overall, and we will only proceed once the previous step is approved.",
        opS8Img: require("../data/images/process/iterations-la.webp"),
        opS8ImgAlt: "video editing iterations novela films private limited",
        // OUR VIDEOS SLIDER
        sliderVL: [24, 20, 4, 54, 55, 56, 101, 102, 103]
    },
    {
        key: 5,
        id: 5,
        //SERVICE AND HELMET INFO
        serviceNAME: "live action product videos",
        serviceDESC: "Live Action Product videos helps you to showcase your products or services by highlighting its features and benefits. Animated product videos are quiet popular in IT and SaaS companies.",
        serviceIMG: require("../data/images/services/parent/product-videos.webp"),
        serviceIMGALT: "product videos novela films private limited",
        serviceLink: "https://novelafilms.com/services/live-action-product-videos",
        serviceKeywords:["live action product videos"],
        // SERVICE PAGE HERO SECTION
        heroIMG: require("../data/images/services/child/live-action-bg-default.webp"),
        heroIMG_SM: require("../data/images/services/child/live-action-bg-sm.webp"),
        heroIMG_MD: require("../data/images/services/child/live-action-bg-md.webp"),
        heroIMG_LG: require("../data/images/services/child/live-action-bg-lg.webp"),
        heroIMGDETAIL: "Live action product videos",
        heroVIDEOURL: "https://www.youtube.com/watch?v=q-4U5lC4tTU",
        heroTITLE1: "Live Action",
        heroTITLE2: "Product",
        heroTITLE3: "Videos",
        // SERVICE DESCRIPTION
        descTEXT1: "From concept to screen, product videos are the catalysts that turn ordinary objects into\n extraordinary experiences.",
        descTEXT2: "",
        descTEXT3: "What is a Live Action Product Video?",
        descTEXT4: "A Live Action product video is a short, engaging video used for marketing purposes to showcase a product's features and benefits. Here's a breakdown of key points:",
        descTEXT5: "",
        descTEXT6: [
            {
                "title": "Purpose:",
                "description": "A product video aims to attract customers and drive sales by visually presenting the product and its value proposition."
            },
            {
                "title": "Format:",
                "description": "It is typically a concise, attention-grabbing video that can be shared online, on websites, and social media platforms."
            },
            {
                "title": "Content:",
                "description": "The video showcases the product's features, demonstrating how it solves a problem or enhances the user's life."
            },
            {
                "title": "Elements:",
                "description": "It includes visuals of the product in action, narration, text, or music to convey the message effectively."
            },
            {
                "title": "Examples:",
                "description": "Product videos can be created for various products such as smartphones, fitness trackers, kitchen appliances, software applications, and fashion items."
            }
        ],
        descIMG: require("../data/images/services/child/live-action-default.webp"),
        descIMG_SM: require("../data/images/services/child/live-action-sm.webp"),
        descIMG_MD: require("../data/images/services/child/live-action-md.webp"),
        descIMGALT: "live action product videos",
        descTEXT7: "The ultimate objective of a product video is to captivate the audience, highlight the product's unique selling points, and persuade viewers to",
        descTEXT8: " make a purchase.",
        // WHY CHOOSE NOVELA
        wcnEXP: "With 15+ years of deep expertise in the product video production space, we have honed our skills and gained invaluable insights. Our wealth of knowledge allows us to tackle any challenge with confidence and precision. Trust in our expertise to deliver exceptional results and meet your unique requirements.",
        wcnFD: "At Novela Films, we thrive on the challenge of working with fixed deadlines. With our dedicated team and streamlined processes, we excel at delivering high quality results within  given timeframes. Our commiment to meeting fixed deadlines ensures that your projects are completed on time, without compromising on quality or creativity.",
        wcnQC: "We prioritize quality control to ensure exceptional results. Our dedicated team works diligently to implement rigorous quality checks throughout every stage of the process. Our commitment to quality guarantees client satisfaction and sets us apart from the competition.",
        wcnSO: "Our product video production process is meticulously structured, ensuring clarity and high level of organization. It encompasses well-defined steps, multiple layers of quality control, and fixed turnaround times, providing our clients with a seamless journey from initiation to completion.",
        wcnTOE: "Novela Films boasts a skilled and passionate team of experts, including editors, producers, directors, animators, and writers. Through their collaboration, we ensure meticulous attention to detail and deliver exceptional animated explainer videos that captivate and engage your audience. Trust us to handle your project with professionalism and creativity.",
        wcnTP: "During our initial call, we have a thorough discussion about your requirements for an product video. Based on this, we create a detailed proposal that outlines how we will bring your ideas to life. You can trust us to be transparent and there will be no hidden fees or unexpected costs.",
         // FLAG 1
         flagText: "Create a Product Video!",
         flag2Text: "Work on Your Next Video!",
        // OUR WORK
        // owTEXT: "Take a tour of our Corporate portfolio and you’ll see a perfect blend of creativity and innovation.",
        owTEXT: "Witness a delightful fusion of creativity and innovation.\n\n We invite you to explore a diverse collection that showcases our unique approach in product video space.\n\n Take a moment to discover the magic within our portfolio.",
        videoList: [4, 7, 21, 47, 50, 57],
        // OUR PROCESS STEP 1
        opS1Title: "Video Brief",
        opS1Text: "A concise product video brief aligns stakeholders, ensures clarity in objectives, content, style, and messaging. It saves time, promotes efficiency, minimizes revisions, and acts as a roadmap to keep production focused on goals and target audience. It facilitates evaluation, feedback, and productive discussions for adjustments.",
        opS1Img: require("../data/images/process/video-brief.webp"),
        opS1ImgAlt: "video editing brief novela films private limited",
        // OUR PROCESS STEP 2
        opS2Title: "The Kick-Off Call",
        opS2Text: "During the kick-off call, team members introduce themselves and discuss project scope, roles and responsibilities, and the communication plans. The project timeline is outlined, challenges are identified, and next steps are determined. This call establishes expectations and promotes successful collaboration, communication, and project management for the duration of the project.",
        opS2Img: require("../data/images/process/kick-off-call.webp"),
        opS2ImgAlt: "video editing kick off call novela films private limited",
        // OUR PROCESS STEP 3
        opS3Title: "Script",
        opS3Text: "The script ensures the message is delivered in a concise and engaging manner, utilizing storytelling techniques to captivate the audience. The script also fosters collaboration, maintains control over timing and information flow, and effectively communicates the video's intended message and goals.",
        opS3Img: require("../data/images/process/script.webp"),
        opS3ImgAlt: "video editing script novela films private limited",
        // OUR PROCESS STEP 4
        opS4Title: "Pre-Production",
        opS4Text: "Pre-production is the initial phase of filmmaking or video production. It involves planning, budgeting, storyboarding, casting, location scouting, and assembling the production team. This stage sets the foundation for a successful production, organizing creative aspects, schedules, and key decisions before filming.",
        opS4Img: require("../data/images/process/pre-production.webp"),
        opS4ImgAlt: "video editing pre-production novela films private limited",
        // OUR PROCESS STEP 5
        opS5Title: "Production / On-set Filming",
        opS5Text: "Production encompasses the act of capturing footage either on location or in a controlled studio enviroment. A meticulously executed production or on-set filming significantly enhances the overall impact of a product video by crafting visually captivating and compelling content that truly engages the target audience.",
        opS5Img: require("../data/images/process/production.webp"),
        opS5ImgAlt: "video editing importing media novela films private limited",
        // OUR PROCESS STEP 6
        opS6Title: "Post Production",
        opS6Text: "Post-production is the final step in video creation. During this stage, the footage is edited, and elements like visual effects and sound design are included to enhance the viewer's experience. It's an important process that fine-tunes and enhances the video, resulting in a captivating and impactful final product that resonates with the audience.",
        opS6Img: require("../data/images/services/child/animated-explainer-video-default.webp"),
        opS6ImgAlt: "video editing novela films private limited",
        // OUR PROCESS STEP 7
        opS7Title: "Draft Video",
        opS7Text: "Once the production of the video is completed, a draft video will be shared for approval and any suggested changes will be incorporated at this stage.",
        opS7Img: require("../data/images/process/draft-video-2.webp"),
        opS7ImgAlt: "video editing draft video novela films private limited",
        // OUR PROCESS STEP 8
        opS8Title: "Finalization and Delivery",
        opS8Text: "Once the draft is approved and payments are made, we proceed to share the final video, fully polished and ready for distribution, ensuring client satisfaction and effective product showcasing.",
        opS8Img: require("../data/images/process/final-delivery.webp"),
        opS8ImgAlt: "video editing iterations novela films private limited",
        // OUR VIDEOS SLIDER
        sliderVL: [4, 7, 21, 47, 50, 57, 101, 102, 103]        
    },
    {
        key: 6,
        id: 6,
        //SERVICE AND HELMET INFO
        serviceNAME: "web series & short films",
        serviceDESC: "Explore our captivating world of web series, compelling short films, and intriguing fiction collection. Immerse yourself in diverse productions that promise an extraordinary viewing experience.",
        serviceIMG: require("../data/images/services/parent/web-series.webp"),
        serviceIMGALT: "web series videos novela films private limited",
        serviceLink: "https://novelafilms.com/services/web-series",
        serviceKeywords:["web series, short films"],
        // SERVICE PAGE HERO SECTION
        heroIMG: require("../data/images/services/child/web-series-bg-default.webp"),
        heroIMG_SM: require("../data/images/services/child/web-series-bg-sm.webp"),
        heroIMG_MD: require("../data/images/services/child/web-series-bg-md.webp"),
        heroIMG_LG: require("../data/images/services/child/web-series-bg-lg.webp"),
        heroIMGDETAIL: "web series and short films",
        heroVIDEOURL: "https://www.youtube.com/watch?v=q-4U5lC4tTU",
        heroTITLE1: "Web Series\n & Short Films",
        heroTITLE2: "Production",
        // SERVICE DESCRIPTION
        descTEXT1: "From YouTube to Netflix, web series are redefining entertainment.",
        descTEXT2: "",
        descTEXT3: "What is Web Series \n& Short Films Production?",
        descTEXT4: "A web series is a form of digital entertainment consisting of a series of scripted or non-scripted videos, generally in an episodic format, released on the internet or via an online platform. Web series are typically shorter in length than traditional television series, often ranging from a few minutes to half an hour per episode, although there can be exceptions.",
        descTEXT5: "Short films are concise cinematic works that narrate a story, communicate a message, or delve into a theme within a limited time frame. Unlike full-length movies, which commonly exceed 90 minutes, short films are typically much shorter, often spanning just a few minutes to around 40 minutes. However, there are no rigid guidelines governing their duration. Short films encompass a wide spectrum of genres, such as drama, comedy, documentary, animation, and experimental. The power of web series and short films lies in their ability to:",
        descTEXT6: [
            {
                "title": "Concise Storytelling"
            },
            {
                "title": "Offer Diverse Genres"
            },
            {
                "title": "Cost-Effective Production"
            },
            {
                "title": "Emotional Appeal"
            },
            {
                "title": "Online Distribution"
            },
            {
                "title": "Independent Creators"
            }
        ],
        descIMG: require("../data/images/services/child/web-series-default.webp"),
        descIMG_SM: require("../data/images/services/child/web-series-sm.webp"),
        descIMG_MD: require("../data/images/services/child/web-series-md.webp"),
        descIMGALT: "web series short films",
        descTEXT7: "\"Small screens, big stories. That's the magic of web series.\"",
        descTEXT9: "",
        // WHY CHOOSE NOVELA
        wcnEXP: "With 15+ years of deep expertise in the web series and short film production space, we have honed our skills and gained invaluable insights. Our wealth of knowledge allows us to tackle any challenge with confidence and precision. Trust in our expertise to deliver exceptional results and meet your unique requirements.",
        wcnFD: "At Novela Films, we thrive on the challenge of working with fixed deadlines. With our dedicated team and streamlined processes, we excel at delivering high quality results within given timeframes. Our commiment to meeting fixed deadlines ensures that your projects are completed on time, without compromising on quality or creativity.",
        wcnQC: "We prioritize quality control to ensure exceptional results. Our dedicated team works diligently to implement rigorous quality checks throughout every stage of the process. Our commitment to quality guarantees client satisfaction and sets us apart from the competition.",
        wcnSO: "Our video web series and short film production process is meticulously structured, ensuring clarity and high level of organization. It encompasses well-defined steps, multiple layers of quality control, and fixed turnaround times, providing our clients with a seamless journey from initiation to completion.",
        wcnTOE: "Novela Films boasts a skilled and passionate team of experts, including video editors, producers, directors, animators, and writers. Through their collaboration, we ensure meticulous attention to detail and deliver exceptional edited videos that captivate and engage your audience. Trust us to handle your project with professionalism and creativity.",
        wcnTP: "During our initial call, we have a thorough discussion about your requirements for a web series or short film . Based on this, we create a detailed proposal that outlines how we will bring your ideas to life. You can trust us to be transparent and there will be no hidden fees or unexpected costs.",
        // FLAG 1
        flagText: "Create a Web Series",
        flag2Text: "Work on Your Next Video!",
        // OUR WORK
        // owTEXT: "Take a tour of our Corporate portfolio and you’ll see a perfect blend of creativity and innovation.",
        owTEXT: "Witness a delightful fusion of creativity and innovation.\n\n We invite you to explore a diverse collection that showcases our unique approach in web series and short film production space.\n\n Take a moment to discover the magic within our portfolio.",
        videoList: [20, 58, 59, 60, 61, 62],
        // OUR PROCESS STEP 1
        opS1Title: "Video Brief",
        opS1Text: "A concise web series and short film production brief aligns stakeholders, ensures clarity in objectives, content, style, and messaging. It saves time, promotes efficiency, minimizes revisions, and acts as a roadmap to keep production focused on goals and target audience. It facilitates evaluation, feedback, and productive discussions for adjustments.",
        opS1Img: require("../data/images/process/video-brief.webp"),
        opS1ImgAlt: "video editing brief novela films private limited",
        // OUR PROCESS STEP 2
        opS2Title: "The Kick-Off Call",
        opS2Text: "During the kick-off call, team members introduce themselves and discuss project scope, roles and responsibilities, and the communication plans. The project timeline is outlined, challenges are identified, and next steps are determined. This call establishes expectations and promotes successful collaboration, communication, and project management for the duration of the project.",
        opS2Img: require("../data/images/process/kick-off-call.webp"),
        opS2ImgAlt: "video editing kick off call novela films private limited",
        // OUR PROCESS STEP 3
        opS3Title: "Script",
        opS3Text: "The script ensures the message is delivered in a concise and engaging manner, utilizing storytelling techniques to captivate the audience. We typically handle script creation, but we value and welcome client input, especially for any special requirements they may have.",
        opS3Img: require("../data/images/process/script.webp"),
        opS3ImgAlt: "video editing script novela films private limited",
        // OUR PROCESS STEP 4
        opS4Title: "Pre-Production",
        opS4Text: "Pre-production is the initial phase of filmmaking or video production. It's when storyboards are created to visualize the scenes, and actors are chosen after auditions to fit the roles. This stage sets the foundation for a successful production, organizing creative aspects, schedules, and key decisions before editing.",
        opS4Img: require("../data/images/process/pre-production-ve.webp"),
        opS4ImgAlt: "video editing pre-production novela films private limited",
        // OUR PROCESS STEP 5
        opS5Title: "Production / On-set Filming",
        opS5Text: "Production encompasses the act of capturing footage either on location or in a controlled studio enviroment. A meticulously executed production or on-set filming significantly enhances the overall impact of a product video by crafting visually captivating and compelling content that truly engages the target audience.",
        opS5Img: require("../data/images/process/production.webp"),
        opS5ImgAlt: "video editing importing media novela films private limited",
        // OUR PROCESS STEP 6
        opS6Title: "Post Production",
        opS6Text: "Post-production is the final step in video creation. During this stage, the footage is edited, and elements like visual effects and sound design are included to enhance the viewer's experience. It's an important process that fine-tunes and enhances the video, resulting in a captivating and impactful final product that resonates with the audience.",
        opS6Img: require("../data/images/services/child/animated-explainer-video-default.webp"),
        opS6ImgAlt: "video editing novela films private limited",
        // OUR PROCESS STEP 7
        opS7Title: "Draft Video",
        opS7Text: "Once the production of the video is completed, a draft video will be shared for approval and any suggested changes will be incorporated at this stage.",
        opS7Img: require("../data/images/process/draft-video-1.webp"),
        opS7ImgAlt: "video editing draft video novela films private limited",
        // OUR PROCESS STEP 8
        opS8Title: "Finalization and Delivery",
        opS8Text: "Once the draft is approved and payments are made, we proceed to share the final video, fully polished and ready for distribution, ensuring client satisfaction and effective product showcasing.",
        opS8Img: require("../data/images/process/final-delivery.webp"),
        opS8ImgAlt: "video editing iterations novela films private limited",
        // OUR VIDEOS SLIDER
        sliderVL: [20, 58, 59, 60, 61, 62, 101, 102, 103]
        
    },    
    {
        key: 7,
        id: 7,
        //SERVICE AND HELMET INFO
        serviceNAME: "interviews",
        serviceDESC: "Experience the impact of interview videos as they reveal your brand's journey, passion, and knowledge through conversations with key individuals in your organization.",
        serviceIMG: require("../data/images/services/parent/interviews.webp"),
        serviceIMGALT: "interview videos novela films private limited",
        serviceLink: "/services/interviews",
        serviceKeywords:["interviews"],
        // SERVICE PAGE HERO SECTION
        heroIMG: require("../data/images/services/child/interviews-bg-default.webp"),
        heroIMG_SM: require("../data/images/services/child/interviews-bg-sm.webp"),
        heroIMG_MD: require("../data/images/services/child/interviews-bg-md.webp"),
        heroIMG_LG: require("../data/images/services/child/interviews-bg-lg.webp"),
        heroIMGDETAIL: "web series and shorts production",
        heroVIDEOURL: "https://www.youtube.com/watch?v=q-4U5lC4tTU",
        heroTITLE1: "Business",
        heroTITLE2: "Interviews",
        // SERVICE DESCRIPTION
        descTEXT1: "A well-crafted video interview can bring a brand's message to life like no other medium.",
        descTEXT2: "",
        descTEXT3: "What is\n Business Interviews?",
        descTEXT4: "Business interviews in video production are a valuable tool for businesses to communicate their message effectively, build brand awareness, and connect with their target audience in a more engaging and personal way. hese interviews are often used in corporate videos, marketing materials, documentaries, and other video content created by businesses.",
        descTEXT5: "The power of business interviews lies in their ability to:",
        descTEXT6: [
            {
                "title": "Storytelling"
            },
            {
                "title": "Human Connection"
            },
            {
                "title": "Cost-effective"
            },
            {
                "title": "Emotional Appeal"
            },
            {
                "title": "Improves SEO"
            },
            {
                "title": "Global Reach"
            }
        ],
        descIMG: require("../data/images/services/child/corporate-video-default.webp"),
        descIMG_SM: require("../data/images/services/child/corporate-video-sm.webp"),
        descIMG_MD: require("../data/images/services/child/corporate-video-md.webp"),
        descIMGALT: "Interviews Production",
        descTEXT7: "Business interviews have the unique ability to connect with an audience on both \n an emotional and intellectual level.",
        descTEXT9: "",
        // WHY CHOOSE NOVELA
        wcnEXP: "With 15+ years of deep expertise in the business interviews space, we have honed our skills and gained invaluable insights. Our wealth of knowledge allows us to tackle any challenge with confidence and precision. Trust in our expertise to deliver exceptional results and meet your unique requirements.",
        wcnFD: "At Novela Films, we thrive on the challenge of working with fixed deadlines. With our dedicated team and streamlined processes, we excel at delivering high quality results within given timeframes. Our commiment to meeting fixed deadlines ensures that your projects are completed on time, without compromising on quality or creativity.",
        wcnQC: "We prioritize quality control to ensure exceptional results. Our dedicated team works diligently to implement rigorous quality checks throughout every stage of the process. Our commitment to quality guarantees client satisfaction and sets us apart from the competition.",
        wcnSO: "Video shooting of our business interviews is meticulously structured, ensuring clarity and high level of organization. It encompasses well-defined steps, multiple layers of quality control, and fixed turnaround times, providing our clients with a seamless journey from initiation to completion.",
        wcnTOE: "Novela Films boasts a skilled and passionate team of experts, including video editors, producers, directors, animators, and writers. Through their collaboration, we ensure meticulous attention to detail and deliver exceptional edited videos that captivate and engage your audience. Trust us to handle your project with professionalism and creativity.",
        wcnTP: "During our initial call, we have a thorough discussion about your expectations from the business interview. Based on this, we create a detailed proposal that outlines how we will bring your ideas to life. You can trust us to be transparent and there will be no hidden fees or unexpected costs.",
        // FLAG 1
        flagText: "Create a Business Interview ",
        flag2Text: "Work on Your Next Video!",
        // OUR WORK
        // owTEXT: "Take a tour of our Corporate portfolio and you’ll see a perfect blend of creativity and innovation.",
        owTEXT: "Witness a delightful fusion of creativity and innovation.\n\n We invite you to explore a diverse collection that showcases our unique approach in business interviews space.\n\n We have shot more than 100+ business interviews with top CEO's / Celebrities in India.\n\n Take a moment to discover the magic within our portfolio.",
        videoList: [9, 13, 16, 18, 24, 28],
        // OUR PROCESS STEP 1
        opS1Title: "Video Brief",
        opS1Text: "A concise briefing ensures alignment among stakeholders, establishing clarity in objectives, content, interview approach, and messaging. It saves time, streamlines processes, reduces the need for revisions, and acts as a roadmap to keep production focused on goals and the intended audience. It simplifies evaluation, feedback, and productive discussions for making necessary adjustments.",
        opS1Img: require("../data/images/process/video-brief.webp"),
        opS1ImgAlt: "business interviews brief novela films private limited",
        // OUR PROCESS STEP 2
        opS2Title: "The Kick-Off Call",
        opS2Text: "During the kick-off call, team members introduce themselves and discuss project scope, roles and responsibilities, and the communication plans. The project timeline is outlined, challenges are identified, and next steps are determined. This call establishes expectations and promotes successful collaboration, communication, and project management for the duration of the project.",
        opS2Img: require("../data/images/process/kick-off-call.webp"),
        opS2ImgAlt: "business interviews kick off call novela films private limited",
        // OUR PROCESS STEP 3
        opS3Title: "Interview Questions",
        opS3Text: "During this process, we develop a list of interview questions that highlight essential conversation points. These questions serve as a framework for both the interviewer and interviewee, with room for spontaneous follow-up inquiries based on the interviewee's responses.",
        opS3Img: require("../data/images/process/questions.webp"),
        opS3ImgAlt: "business interviews script novela films private limited",
        // OUR PROCESS STEP 4
        opS4Title: "Pre-Production",
        opS4Text: "During the pre-production phase, several tasks are undertaken, including location scouting, scheduling, equipment preparation, wardrobe coordination, makeup arrangements, and crew assignments, all contributing to a seamless shooting process.",
        opS4Img: require("../data/images/process/pre-production.webp"),
        opS4ImgAlt: "business interviews pre-production novela films private limited",
        // OUR PROCESS STEP 5
        opS5Title: "Production / On-set Filming",
        opS5Text: "Production comprises the essential task of recording footage, whether it occurs on location or within a controlled studio setup. The careful execution of production and on-set filming plays a pivotal role in elevating the overall effectiveness of a business interview, producing visually striking and truly engaging content for the desired viewers.",
        opS5Img: require("../data/images/process/production.webp"),
        opS5ImgAlt: "business interviews importing media novela films private limited",
        // OUR PROCESS STEP 6
        opS6Title: "Post Production",
        opS6Text: "Post-production is the final step in video creation. During this stage, the footage is edited, and elements like visual effects and sound design are included to enhance the viewer's experience. It's an important process that fine-tunes and enhances the video, resulting in a captivating and impactful final product that resonates with the audience.",
        opS6Img: require("../data/images/process/post-production-int.webp"),
        opS6ImgAlt: "business interviews novela films private limited",
        // OUR PROCESS STEP 7
        opS7Title: "Draft Video",
        opS7Text: "Once the production of the video is completed, a draft video will be shared for approval and any suggested changes will be incorporated at this stage.",
        opS7Img: require("../data/images/process/draft-video-1.webp"),
        opS7ImgAlt: "business interviews draft video novela films private limited",
        // OUR PROCESS STEP 8
        opS8Title: "Finalization and Delivery",
        opS8Text: "Once the draft is approved and payments are made, we proceed to share the final video, fully polished and ready for distribution, ensuring client satisfaction and effective product showcasing.",
        opS8Img: require("../data/images/process/final-delivery.webp"),
        opS8ImgAlt: "business interviews iterations novela films private limited",
        // OUR VIDEOS SLIDER
        sliderVL: [9, 13, 16, 18, 24, 28, 101, 102, 103]
    },
    {
        key: 8,
        id: 8,
        //SERVICE AND HELMET INFO
        serviceNAME: "mobile app explainer videos",
        serviceDESC: "Mobile app explainer videos are used to showcase app features, illustrate effective problem-solving, convey vital information, provide app walkthroughs, and boost app adoption through compelling visual storytelling.",
        serviceIMG: require("../data/images/services/parent/mobile-app-explainer-videos.webp"),
        serviceIMGALT: "mobile app explainer videos novela films private limited",
        serviceLink: "https://novelafilms.com/services/mobile-app-explainer-videos",
        serviceKeywords:["mobile app explainer videos"],
        // SERVICE PAGE HERO SECTION
        heroIMG: require("../data/images/services/child/mobile-explainer-bg-default.webp"),
        heroIMG_SM: require("../data/images/services/child/mobile-explainer-bg-default.webp"),
        heroIMG_MD: require("../data/images/services/child/mobile-explainer-bg-default.webp"),
        heroIMG_LG: require("../data/images/services/child/mobile-explainer-bg-default.webp"),
        heroIMGDETAIL: "mobile app explainer videos",
        heroVIDEOURL: "https://www.youtube.com/watch?v=q-4U5lC4tTU",
        heroTITLE1: "Mobile App",
        heroTITLE2: "Explainer\n Videos",
        // SERVICE DESCRIPTION
        descTEXT1: "Mobile app explainer videos are the visual ambassadors that introduce your app to the world,\n leaving a lasting impression.",
        descTEXT2: "",
        descTEXT3: "What is Mobile App\n Explainer Videos?",
        descTEXT4: "Mobile app explainer video is a short, engaging, and informative video created to introduce, explain, and promote a mobile application (app). These videos are designed to help potential users understand the app's functionality, features, and benefits quickly and effectively. ",
        descTEXT5: "Mobile app explainer videos are a powerful marketing tool that simplifies complex concepts and demonstrates the value of the app in a visually appealing and engaging way. The power of mobile app explainer video lies in their ability to:",
        descTEXT6: [
            {
                "title": "Simplify Your Message"
            },
            {
                "title": "Boost Conversions"
            },
            {
                "title": "Generate Leads"
            },
            {
                "title": "App Demonstration"
            },
            {
                "title": "Foster Brand Awareness"
            },
            {
                "title": "Communicate Concisely"
            }
        ],
        descIMG: require("../data/images/services/child/mobile-explainer-default.webp"),
        descIMG_SM: require("../data/images/services/child/mobile-explainer-sm.webp"),
        descIMG_MD: require("../data/images/services/child/mobile-explainer-md.webp"),
        descIMGALT: "mobile app explainer video",
        descTEXT7: "Your mobile app's distinctive edge can be amplified by the artful creation of an explainer video,\n helping it shine amidst a crowded app marketplace.",
        descTEXT9: "",
        // WHY CHOOSE NOVELA
        wcnEXP: "With 15+ years of deep expertise in the mobile app explainer video space, we have honed our skills and gained invaluable insights. Our wealth of knowledge allows us to tackle any challenge with confidence and precision. Trust in our expertise to deliver exceptional results and meet your unique requirements.",
        wcnFD: "At Novela Films, we thrive on the challenge of working with fixed deadlines. With our dedicated team and streamlined processes, we excel at delivering high quality results within given timeframes. Our commiment to meeting fixed deadlines ensures that your projects are completed on time, without compromising on quality or creativity.",
        wcnQC: "We prioritize quality control to ensure exceptional results. Our dedicated team works diligently to implement rigorous quality checks throughout every stage of the process. Our commitment to quality guarantees client satisfaction and sets us apart from the competition.",
        wcnSO: "Our mobile app explainer video production process is meticulously structured, ensuring clarity and high level of organization. It encompasses well-defined steps, multiple layers of quality control, and fixed turnaround times, providing our clients with a seamless journey from initiation to completion.",
        wcnTOE: "Novela Films boasts a skilled and passionate team of experts, including video editors, producers, directors, animators, and writers. Through their collaboration, we ensure meticulous attention to detail and deliver exceptional edited videos that captivate and engage your audience. Trust us to handle your project with professionalism and creativity.",
        wcnTP: "During our initial call, we have a thorough discussion about your requirements for mobile app explainer video. Based on this, we create a detailed proposal that outlines how we will bring your ideas to life. You can trust us to be transparent and there will be no hidden fees or unexpected costs.",
        // FLAG 1
        flagText: "Create a Mobile App Explainer Video",
        flag2Text: "Work on Your Next Video!",
        // OUR WORK
        // owTEXT: "Take a tour of our Corporate portfolio and you’ll see a perfect blend of creativity and innovation.",
        owTEXT: "Witness a delightful fusion of creativity and innovation.\n\n We invite you to explore a diverse collection that showcases our unique approach in mobile app explainer video production space.\n\n Take a moment to discover the magic within our portfolio.",
        videoList: [6, 26, 27, 42, 43, 53],
        // OUR PROCESS STEP 1
        opS1Title: "Video Brief",
        opS1Text: "A clear and concise mobile app explainer video brief aligns stakeholders, ensures clarity in objectives, content, style, and messaging. It saves time, promotes efficiency, minimizes revisions, and acts as a roadmap to keep production focused on goals and target audience. It facilitates evaluation, feedback, and productive discussions for adjustments.",
        opS1Img: require("../data/images/process/video-brief.webp"),
        opS1ImgAlt: "mobile app explainer video brief novela films private limited",
        // OUR PROCESS STEP 2
        opS2Title: "The Kick-Off Call",
        opS2Text: "During the kick-off call, team members introduce themselves and discuss project scope, roles and responsibilities, and the communication plans. The project timeline is outlined, challenges are identified, and next steps are determined. This call establishes expectations and promotes successful collaboration, communication, and project management for the duration of the project.",
        opS2Img: require("../data/images/process/kick-off-call.webp"),
        opS2ImgAlt: "mobile app explainer kick off call novela films private limited",
        // OUR PROCESS STEP 3
        opS3Title: "Script",
        opS3Text: "The script ensures the message is delivered in a concise and engaging manner, utilizing storytelling techniques to captivate the audience. The script also fosters collaboration, maintains control over timing and information flow, and effectively communicates the video's intended message and goals.",
        opS3Img: require("../data/images/process/script.webp"),
        opS3ImgAlt: "mobile app explainer script novela films private limited",
        // OUR PROCESS STEP 4
        opS4Title: "Voiceover",
        opS4Text: "The voiceover is the captivating thread that weaves the story together. It plays a vital role in conveying the information, guiding the audience, and enhancing the overall impact of the mobile app explainer video. A well-executed voiceover in a mobile app explainer video should be clear, engaging, and aligned with the intended tone and messaging.",
        opS4Img: require("../data/images/process/voiceover.webp"),
        opS4ImgAlt: "mobile app explainer voiceover novela films private limited",
        // OUR PROCESS STEP 5
        opS5Title: "Storyboarding",
        opS5Text: "A storyboard visually represents the actions and flow of a video, similar to a comic book. The storyboard artist sketches out a series of frames that depict the flow of the video, including the scenes, actions, and characters involved. It serves as a communication tool between creators and clients, facilitating feedback and revisions before production starts.",
        opS5Img: require("../data/images/process/storyboarding.webp"),
        opS5ImgAlt: "mobile app explainer storyboarding novela films private limited",
        // OUR PROCESS STEP 6
        opS6Title: "Animation",
        opS6Text: "The animation part of an mobile app explainer video plays a crucial role in bringing the visual elements to life, enhancing engagement, and effectively communicating the message to the audience. It requires technical skill, creativity, and attention to detail to create animations that are visually appealing, engaging, and forms an emotional connection with the audience.",
        opS6Img: require("../data/images/process/2d-animation.webp"),
        opS6ImgAlt: "mobile app explainer animation novela films private limited",
        // OUR PROCESS STEP 7
        opS7Title: "Draft Video",
        opS7Text: "Once the production of the mobile app explainer video is completed, a draft video will be shared for approval and any suggested changes will be incorporated at this stage.",
        opS7Img: require("../data/images/process/draft-video-1.webp"),
        opS7ImgAlt: "mobile app explainer draft video novela films private limited",
        // OUR PROCESS STEP 8
        opS8Title: "Iterations",
        opS8Text: "At Novela Films, we include two iterations at each step of the process with a capping of six iterations overall, and we will only proceed once the previous step is approved.",
        opS8Img: require("../data/images/process/iterations.webp"),
        opS8ImgAlt: "mobile app explainer iterations novela films private limited",
        // OUR VIDEOS SLIDER
        sliderVL: [6, 26, 27, 42, 43, 53, 101, 102, 103]

    },  
    {
        key: 9,
        id: 9,
        //SERVICE AND HELMET INFO
        serviceNAME: "educational videos",
        serviceDESC: "Explore education video production featuring tutorials, online courses, animations, and beyond. With its clear and engaging approach, it proves highly effective for teaching in diverse disciplines.",
        serviceIMG: require("../data/images/services/parent/educational-videos.webp"),
        serviceIMGALT: "educational videos novela films private limited",
        serviceLink: "https://novelafilms.com/services/educational-videos",
        serviceKeywords:["educational videos"],
        // SERVICE PAGE HERO SECTION
        heroIMG: require("../data/images/services/child/educational-video-bg-default.webp"),
        heroIMG_SM: require("../data/images/services/child/educational-video-bg-default.webp"),
        heroIMG_MD: require("../data/images/services/child/educational-video-bg-default.webp"),
        heroIMG_LG: require("../data/images/services/child/educational-video-bg-default.webp"),
        heroIMGDETAIL: "educational videos",
        heroVIDEOURL: "https://www.youtube.com/watch?v=q-4U5lC4tTU",
        heroTITLE1: "Educational",
        heroTITLE2: "Videos",
        heroTITLE3: "",
        // SERVICE DESCRIPTION
        descTEXT1: "In the world of education, videos are the storytellers, making lessons come alive.",
        descTEXT2: "",
        descTEXT3: "What is an \n Educational Video?",
        descTEXT4: "Educational videos are created specifically to inform, instruct, or teach viewers about a particular subject or topic. These videos serve as a valuable tool for delivering educational content in a visual and engaging format.",
        descTEXT5: "Educational videos are a versatile and effective means of delivering educational content to a broad audience. They have become increasingly popular in both formal and informal learning settings, contributing to the accessibility and effectiveness of education and training programs. The power of educational videos lies in their ability to:",
        descTEXT6: [
            {
                "title": "Enhanced Learning Engagement:"
            },
            {
                "title": "Visual Learning"
            },
            {
                "title": "Accessibility"
            },
            {
                "title": "Consistency"
            },
            {
                "title": "Scalability"
            },
            {
                "title": "Cost-Effectiveness"
            }
        ],
        descIMG: require("../data/images/services/child/educational-video-default.webp"),
        descIMG_SM: require("../data/images/services/child/educational-video-sm.webp"),
        descIMG_MD: require("../data/images/services/child/educational-video-md.webp"),
        descIMGALT: "Animated Explainer Video",
        descTEXT7: "With the magic of e-learning videos, learning becomes a dynamic, interactive experience.",
        descTEXT9: "",
        // WHY CHOOSE NOVELA
        wcnEXP: "With 15+ years of deep expertise in the educational video services space, we have honed our skills and\n gained invaluable insights. Our wealth of knowledge\n allows us to tackle any challenge with confidence and\n precision. Trust in our expertise to deliver exceptional\n results and meet your unique requirements.",
        wcnFD: "At Novela Films, we thrive on the challenge of working with fixed deadlines. With our dedicated team and streamlined processes, we excel at delivering high quality results within given timeframes. Our commiment to meeting fixed deadlines ensures that your projects are completed on time, without compromising on quality or creativity.",
        wcnQC: "We prioritize quality control to ensure exceptional results. Our dedicated team works diligently to implement rigorous quality checks throughout every stage of the process. Our commitment to quality guarantees client satisfaction and sets us apart from the competition.",
        wcnSO: "Our educational video production process is meticulously structured, ensuring clarity and high level of organization. It encompasses well-defined steps, multiple layers of quality control, and fixed turnaround times, providing our clients with a seamless journey from initiation to completion.",
        wcnTOE: "Novela Films boasts a skilled and passionate team of experts, including designers, producers, directors, animators, and writers. Through their collaboration, we ensure meticulous attention to detail and deliver exceptional educational videos that captivate and engage your audience. Trust us to handle your project with professionalism and creativity.",
        wcnTP: "During our initial call, we have a thorough discussion about your requirements for an educational video. Based on this, we create a detailed proposal that outlines how we will bring your ideas to life. You can trust us to be transparent and there will be no hidden fees or unexpected costs.",
        // FLAG 1
        flagText: "Create a Educational Video!",
        flag2Text: "Work on Your Next Video!",
        // OUR WORK
        owTEXT: "Witness a delightful fusion of creativity and innovation.\n\n We invite you to explore a diverse collection that showcases our unique approach in educational video production space.\n\nIn the Edtech space, we have been helping Brands like UpGrad, Uniapply, Lead school, Simplilearn, MIT, Iffco Tokio, Teamlease, Betterplace, etc with their E-learning videos.\n\n Take a moment to discover the magic within our portfolio.",
        videoList: [5, 63, 64, 65, 66, 67],
        // OUR PROCESS STEP 1
        opS1Title: "Video Brief",
        opS1Text: "A clear and concise educational video brief aligns stakeholders, ensures clarity in objectives, content, style, and messaging. It saves time, promotes efficiency, minimizes revisions, and acts as a roadmap to keep production focused on goals and target audience. It facilitates evaluation, feedback, and productive discussions for adjustments.",
        opS1Img: require("../data/images/process/video-brief.webp"),
        opS1ImgAlt: "animation explainer video brief novela films private limited",
        // OUR PROCESS STEP 2
        opS2Title: "The Kick-Off Call",
        opS2Text: "During the kick-off call, team members introduce themselves and discuss project scope, roles and responsibilities, and the communication plans. The project timeline is outlined, challenges are identified, and next steps are determined. This call establishes expectations and promotes successful collaboration, communication, and project management for the duration of the project.",
        opS2Img: require("../data/images/process/kick-off-call.webp"),
        opS2ImgAlt: "animation explainer kick off call novela films private limited",
        // OUR PROCESS STEP 3
        opS3Title: "Script",
        opS3Text: "The script ensures the message is delivered in a concise and engaging manner, utilizing storytelling techniques to captivate the audience. The script also fosters collaboration, maintains control over timing and information flow, and effectively communicates the video's intended message and goals.",
        opS3Img: require("../data/images/process/script.webp"),
        opS3ImgAlt: "animation explainer script novela films private limited",
        // OUR PROCESS STEP 4
        opS4Title: "Voiceover",
        opS4Text: "The voiceover is the captivating thread that weaves the story together. It plays a vital role in conveying the information, guiding the audience, and enhancing the overall impact of the educational video. A well-executed voiceover in a educational video should be clear, engaging, and aligned with the intended tone and messaging.",
        opS4Img: require("../data/images/process/voiceover.webp"),
        opS4ImgAlt: "animation explainer voiceover novela films private limited",
        // OUR PROCESS STEP 5
        opS5Title: "Storyboarding",
        opS5Text: "A storyboard visually represents the actions and flow of a video, similar to a comic book. The storyboard artist sketches out a series of frames that depict the flow of the video, including the scenes, actions, and characters involved. It serves as a communication tool between creators and clients, facilitating feedback and revisions before production starts.",
        opS5Img: require("../data/images/process/storyboarding.webp"),
        opS5ImgAlt: "animation explainer storyboarding novela films private limited",
        // OUR PROCESS STEP 6
        opS6Title: "Post-Production",
        opS6Text: "The post-production part of an educational video plays a crucial role in bringing the visual elements to life, enhancing engagement, and effectively communicating the message to the audience. It requires technical skill, creativity, and attention to detail to create videos that are visually appealing, engaging, and forms an emotional connection with the audience.",
        opS6Img: require("../data/images/process/2d-animation.webp"),
        opS6ImgAlt: "animation explainer animation novela films private limited",
        // OUR PROCESS STEP 7
        opS7Title: "Draft Video",
        opS7Text: "Once the production of the educational video is completed, a draft video will be shared for approval and any suggested changes will be incorporated at this stage.",
        opS7Img: require("../data/images/process/draft-video-1.webp"),
        opS7ImgAlt: "animation explainer draft video novela films private limited",
        // OUR PROCESS STEP 8
        opS8Title: "Iterations",
        opS8Text: "At Novela Films, we include two iterations at each step of the process with a capping of six iterations overall, and we will only proceed once the previous step is approved.",
        opS8Img: require("../data/images/process/iterations-edu.webp"),
        opS8ImgAlt: "animation explainer iterations novela films private limited",
        // OUR VIDEOS SLIDER
        sliderVL: [5, 63, 64, 65, 66, 67, 101, 102, 103]
        
    },
    {
        key: 10,
        id: 10,
        //SERVICE AND HELMET INFO
        serviceNAME: "Animated Video Production Company",
        serviceDESC: "We are stunning creative video content studio that not only make exceptional content, but is built with passion and backed up by awesome team.",
        serviceIMG: require("../data/images/services/child/2d-animation-bg-default.webp"),
        heroIMG: require("../data/images/services/services-bg-default.webp"),
        heroIMG_SM: require("../data/images/services/services-bg-sm.webp"),
        heroIMG_MD: require("../data/images/services/services-bg-md.webp"),
        heroIMG_LG: require("../data/images/services/services-bg-lg.webp"),
        heroIMGDETAIL: "services offered by novela films private limited animated video production company",
        heroVIDEOURL: "https://www.youtube.com/watch?v=z09zhTm8QDk",
        heroTITLE1: "Animated\nVideo Production",
        heroTITLE2: "Company",
        googleIMG: require("../data/images/google.webp"),
        serviceLink: "https://novelafilms.com/services",
        serviceKeywords:["Animated Video Production Company"],   
    }
];

export default serviceslist;